<template>
  <div id="HomePage" ref="scrollableDiv">
    <div class="header-" id="headers">
      <Header></Header>
    </div>
    <!-- 轮播图 -->
    <div id="swiper" class="container-fuild">
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="swiper-slide-title">
              <a href="#/solution">解决方案</a>
              <div class="solutionList___oPNGm" >
                <div class="solutionItem___xgg_P" @click.stop="scrollToDiv('solution')">
                  <div class="content___Pt1_9"><h5>行业解决方案</h5><span>查看更多</span></div>
                  <img src="@/assets/img/software/img_4g.jpg" alt="">
                </div>
                <div class="solutionItem___xgg_P" @click.stop="scrollToDiv('ai')">
                  <div class="content___Pt1_9"><h5>AI+算法</h5><span>查看更多</span></div>
                  <img src="@/assets/img/software/img_ai.jpg" alt="">
                </div>
                <div class="solutionItem___xgg_P" @click.stop="scrollToDiv('cloud')">
                  <div class="content___Pt1_9"><h5>Toco-Cloud云服务</h5><span>查看更多</span></div>
                  <img src="@/assets/img/software/img_cloud.jpg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 行业解决方案 -->
    <div id="bigData" class="container-fuild" ref="solution">
      <div id="industrySolution" class="industrySolution___y4any">
        <div id="target-jie"></div>
        <div data-aos="fade-up" class="title___whYXr aos-init"><h3>行业解决方案</h3>
          <p>
            我们在智慧生活、智慧生产、智慧健康、智慧商业等多个行业为全球提供优质解决方案。我们致力于通过高品质的产品和专业的服务，让生产和生活更加安全，舒适和便捷</p>
        </div>
        <div class="industrySolutionList___XOZqm">
          <div data-aos="fade-right" class="industrySolutionItem___yda1M aos-init">
            <img src="@/assets/img/industry_solution/jiaju.jpg">
            <div class="industrySolutionContent___Bd4C0">
              <img src="@/assets/img/industry_solution/img_solution_industry_icon1.jpg">
              <h5>智慧生活</h5>
              <p>
                对家庭环境的全面监测,保障家居安全与居住舒适度。结合自动化控制,可以实现更智能的照明、空调、家电等管理。另外,还可以利用算法识别居住习惯,实现生活定制和建议。未来,逐步实现生活的智能化辅助与理解,不断提高居住体验与生活质量</p>
            </div>
          </div>
          <div data-aos="fade-left" class="industrySolutionItem___yda1M aos-init">
            <img src="@/assets/img/industry_solution/pasture.jpg">
            <div class="industrySolutionContent___Bd4C0">
              <img src="@/assets/img/industry_solution/img_solution_industry_icon2.jpg">
              <h5>智慧生产</h5>
              <p>
                可以全面监控动物和养殖环境,实现对林牧渔业环境的智能监测,鉴别动物生理与心理状况,提高管理水平。及时发现问题,可以更好维护动物健康,预防疾病;避免或减少环境异常对生长的影响。更丰富的算法实现对动物和环境的深度理解,推动养殖业智能化升级,可持续发展。</p>
            </div>
          </div>
          <div data-aos="fade-right" class="industrySolutionItem___yda1M aos-init">
            <img src="@/assets/img/industry_solution/jiankang.jpg">
            <div class="industrySolutionContent___Bd4C0">
              <img src="@/assets/img/industry_solution/img_solution_industry_icon3.jpg">
              <h5>智慧健康</h5>
              <p>
                全面监测老人/婴幼儿的行为、生理和环境状况,有助于及早发现的身体变化,可以实现更精准健康管理。预防意外,妥善满足生活需求,促进健康生活。结合数据记录,还可以高效安排疫苗接种、定时吃药等提醒事宜。</p>
            </div>
          </div>
          <div data-aos="fade-left" class="industrySolutionItem___yda1M aos-init">
            <img src="@/assets/img/industry_solution/shopping.jpg">
            <div class="industrySolutionContent___Bd4C0">
              <img src="@/assets/img/industry_solution/img_solution_industry_icon4.jpg">
              <h5>智慧商业</h5>
              <p>
                全面感知店铺状态与客户反馈,实时发现问题,预测业务趋势。零售商业将向数字化、个性化和无人值守方向发展。摄像头及其算法将发挥越来越重要作用,成为智能零售不可或缺的重要设备与工具</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ai+算法 -->
    <div id="bigData" class="container-fuild" ref="ai">
      <div id="ai" class="ai___Msl7c">
        <div id="target-Ai"></div>
        <div data-aos="fade-up" class="title___whYXr aos-init aos-animate"><h3>AI算法</h3>
          <p>
            大谷物联不仅研发出领先的AI算法，还开发了多种智能视觉的AI产品，带来更安全更智能的生活体验。大谷物联开发者团队已经完成了将算法转换为产品的所有工作</p>
        </div>
        <div class="aiList___qWx1V">
          <div data-aos="flip-up" data-aos-delay="0" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_1.jpg"
            alt=""><span>人脸识别</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="100" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_2.jpg"
            alt=""><span>面部表情识别</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="200" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_3.jpg"
            alt=""><span>人体姿态检测</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="300" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_4.jpg"
            alt=""><span>声音检测</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="400" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_5.jpg"
            alt=""><span>啼哭检测</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="500" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_6.jpg"
            alt=""><span>异常行为检测</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="600" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_7.jpg"
            alt=""><span>睡眠监测</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="700" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_8.jpg"
            alt=""><span>智能追踪</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="800" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_9.jpg"
            alt=""><span>环境检测</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="900" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_10.jpg"
            alt=""><span>动物检测和计数</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="1000" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_11.jpg"
            alt=""><span>林区火灾检测</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="1100" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_12.jpg"
            alt=""><span>树木检测和计数</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="1200" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_13.jpg"
            alt=""><span>客户轨迹分析</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="1300" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_14.jpg"
            alt=""><span>人流量统计</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="1400" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_15.jpg"
            alt=""><span>客户画像分析</span>
          </div>
          <div data-aos="flip-up" data-aos-delay="1500" class="aiItem___fbQV7 aos-init"><img
            src="@/assets/img/ai/ic_AI_16.jpg"
            alt=""><span>智能结账</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 云服务 -->
    <div id="bigData" class="container-fuild" style="margin: 0px 4%;" ref="cloud">
      <div id="cloud" class="cloud___oo3M8">
        <div id="target-cloud"></div>
        <div data-aos="fade-up" class="title___whYXr aos-init aos-animate"><h3>云服务</h3>
          <p>
            提供安全可靠的云服务，依托自研技术与全球化布局，为用户提供高性能、金融级安全稳定与优质价格比的云服务，致力于服务全球用户与企业</p>
        </div>
        <div class="cloudList___SaFUA">
          <div data-aos="fade-up" class="cloudListItem___Gaxiy aos-init">
            <img src="@/assets/img/cloud/img_solution_cloud_1.jpg" alt="">
            <div class="content___Pt1_9">
              <h5>存储服务</h5>
              <p>对象存储、文件存储、块存储等,用于存储海量图片、视频和数据</p>
            </div>
          </div>
          <div data-aos="fade-up" class="cloudListItem___Gaxiy aos-init">
            <img src="@/assets/img/cloud/img_solution_cloud_2.jpg" alt="">
            <div class="content___Pt1_9">
              <h5>计算服务</h5>
              <p>虚拟机、容器服务、无服务器函数等,用于部署视频分析和AI算法</p>
            </div>
          </div>
          <div data-aos="fade-up" class="cloudListItem___Gaxiy aos-init">
            <img src="@/assets/img/cloud/img_solution_cloud_3.jpg" alt="">
            <div class="content___Pt1_9">
              <h5>视频服务</h5>
              <p>视频上传、编码、分发、播放等,满足专业视频处理需求</p>
            </div>
          </div>
          <div data-aos="fade-up" class="cloudListItem___Gaxiy aos-init">
            <img src="@/assets/img/cloud/img_solution_cloud_4.jpg" alt="">
            <div class="content___Pt1_9">
              <h5>消息服务</h5>
              <p>发布订阅消息等,用于服务和系统间通信</p>
            </div>
          </div>
          <div data-aos="fade-up" class="cloudListItem___Gaxiy aos-init">
            <img src="@/assets/img/cloud/img_solution_cloud_5.jpg" alt="">
            <div class="content___Pt1_9">
              <h5>安全服务</h5>
              <p>数据加密、访问控制、DDoS防护、监控审计等,保障服务安全</p>
            </div>
          </div>
          <div data-aos="fade-up" class="cloudListItem___Gaxiy aos-init">
            <img src="@/assets/img/cloud/img_solution_cloud_6.jpg" alt="">
            <div class="content___Pt1_9"><h5>AI服务</h5>
              <p>提供各种预训练模型和算法,人脸识别、目标检测、语音识别等</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 探索-->
    <div id="bigData" class="container-fuild">
      <div data-aos="zoom-in" class="solutionCard___DLpeY aos-init aos-animate"><h4 class="colorfulTitle">
        让生活更智能,让城市更聪慧</h4>
        <p
          style="color: #fff;font-size: 20px;line-height: 28px;padding-right: 20px;">
          物联网时代,万物互联成为大势所趋。我们期待与更多合作伙伴携手共同探索,让生活更智能,让城市更聪慧</p></div>
    </div>
    <Footer ></Footer>

  </div>
</template>
<script>

export default {
  name: "HomePage",
  methods: {
    scrollToDiv(refName) {
      const targetDiv = this.$refs[refName];
      console.log(refName)
      // 使用scrollIntoView方法滚动到目标<div>
      targetDiv.scrollIntoView({
        behavior: 'smooth' // 可选，平滑滚动效果
      });
    },
    change (refName){
      if(refName=='target-cloud'){
        this.scrollToDiv('cloud');
      }else if(refName=='target-Ai'){
        this.scrollToDiv('ai');
      }else{
        this.scrollToDiv('solution');
      }
    }
  },
  mounted() {
    // 从路由参数中获取目标元素的引用名称
    const refName = this.$route.query.refName;
    if (refName) {
      this.change(refName)
    }
  },
  watch: {
    '$route.query.refName': {
      immediate: true,
      handler(newRefName) {
        if (newRefName) {
          this.change(newRefName);
        }
      }
    }
  }
};
</script>
<style scoped>
/*探索*/
.solutionCard___DLpeY {
  box-sizing: border-box;
  margin: 120px 0;
  padding-left: 68px;
  padding-bottom: 120px;
  background: #000000;
  border-radius: 50px;
}
.solutionCard___DLpeY h4 {
  margin: 120px 0 22px;
  font-weight: 600;
  font-size: 54px;
}
.colorfulTitle {
  display: inline-block;
  color: transparent;
  background-image: linear-gradient(to right,#4162ff,#7326fe,#f77d1b);
  -webkit-background-clip: text;
  background-clip: text;
}

[data-aos=zoom-in] {
  transform: scale(.6);
}
/*ai+算法*/
.title___whYXr h3 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 40px;
}

.cloud___oo3M8 {
  padding-top: 120px;
}

.title___whYXr p {
  max-width: 980px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 28px;
}

.ai___Msl7c .aiList___qWx1V {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;
  padding: 0 160px;
  text-align: center;
}

.ai___Msl7c .aiList___qWx1V .aiItem___fbQV7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 122px;
  margin-bottom: 50px;
}

.ai___Msl7c .aiList___qWx1V .aiItem___fbQV7 img {
  width: 50px;
  height: 50px;
  margin-bottom: 22px;
}

.ai___Msl7c .aiList___qWx1V .aiItem___fbQV7 span {
  font-size: 16px;
}

/*行业解决方案*/
.industrySolution___y4any {
  padding-top: 120px;
}

.industrySolution___y4any .industrySolutionList___XOZqm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.industrySolution___y4any .industrySolutionList___XOZqm .industrySolutionItem___yda1M > img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 550px;
  height: 383px;
}

.industrySolutionItem___yda1M .industrySolutionContent___Bd4C0 {
  position: relative;
  padding: 40px 90px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
}

.industrySolutionItem___yda1M .industrySolutionContent___Bd4C0 img {
  position: absolute;
  top: 38px;
  left: 40px;
  width: 32px;
  height: 32px;
}

.industrySolutionItem___yda1M .industrySolutionContent___Bd4C0 h5 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 30px;
}

.industrySolution___y4any .industrySolutionList___XOZqm .industrySolutionItem___yda1M {
  position: relative;
  width: 550px;
  padding-top: 370px;
  margin: 80px auto 0;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 6px 20px #0000000a;
}

/*云服务*/
.ai___Msl7c{
  padding-top: 120px;
}
.cloud___oo3M8 .cloudList___SaFUA {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.cloud___oo3M8 .cloudList___SaFUA .cloudListItem___Gaxiy {
  margin-bottom: 50px;
}

.cloud___oo3M8 .cloudList___SaFUA .cloudListItem___Gaxiy img {
  width: 360px;
  height: 200px;
  border-radius: 20px;
}

.cloud___oo3M8 .cloudList___SaFUA .cloudListItem___Gaxiy .content___Pt1_9 {
  margin-left: 30px;
}

.cloud___oo3M8 .cloudList___SaFUA .cloudListItem___Gaxiy .content___Pt1_9 h5 {
  margin: 15px 0 10px;
  font-weight: 600;
  font-size: 24px;
}

.cloud___oo3M8 .cloudList___SaFUA .cloudListItem___Gaxiy .content___Pt1_9 p {
  width: 300px;
  font-size: 14px;
  line-height: 20px;
}

.title___whYXr h3 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 40px;
}

.title___whYXr {
  text-align: center;
}

/*解决方案*/
.swiper-slide-title > a:before {
  position: absolute;
  content: "";
  top: 50%;
  left: -110px;
  width: 82px;
  height: 1px;
  background: #979797;
}

.solutionList___oPNGm .solutionItem___xgg_P {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  margin: 0 10px;
  padding: 20px 10px 30px 34px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 20px #0000000a;
  box-sizing: border-box;
  cursor: pointer;
}

.swiper-slide-title > a {
  font-weight: 100;
  font-size: 13px;
  position: relative;
  display: inline-block;
  margin: 50px 110px;
  color: #1e73be;
}

.solutionList___oPNGm .solutionItem___xgg_P .content___Pt1_9 span {
  color: #000;
  font-size: 14px;
  transition: all .3s;
}

.solution___a6j1F .solutionList___oPNGm {
  display: flex;
  justify-content: space-between;
}

.solutionList___oPNGm {
  display: flex;
  justify-content: space-between;
}

/*左到右渐入效果*/
.animation-left {
  animation: fadeInLeft 1s ease-in-out forwards;
}

/*技术创新优势*/
.aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

.prodectAdvantage___Ss8ws h4 {
  font-weight: 600;
  font-size: 40px;
  text-align: center;
}


dl {
  margin-top: 30px;
}

dd::before {
  margin: 7px 0 0px;
  content: "●";
  display: inline-block;
  margin-right: 5px;
}

dl dt {
  font-weight: 400;
  color: #1e73be;
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/*右到左渐入效果*/
.animation-right {
  animation: fadeInRight 1s ease-in-out forwards;
}

@keyframes fadeInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* 整体盒子 */
#HomePage {
  width: 100%;
  max-width: 1857px;
  margin: 0 auto;
}


/* 轮播图 */
#swiper {
  height: 310px;
}

#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}

.solutionItem___xgg_P .content___Pt1_9 h5 {
  margin: 20px 0 15px;
  color: #1e73be;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

#swiper .banner-swiper .swiper-slide {
  position: relative;
}

#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  padding: 0 20% 0 20%;
}

.solutionList___oPNGm .solutionItem___xgg_P img {
  width: 100px;
  height: 60px;
}

#swiper .banner-swiper .swiper-slide-title > h1 {
  font-size: 50px;
  margin-top: 12%;
}

#swiper .banner-swiper .swiper-slide-title > p {
  font-size: 14px;
  color: #fff;
  height: 20px;
  margin: auto;

}

/* App产品介绍 */
#bigData {
  padding: 20px 220px;
  transition: all ease 0.6s;
  box-sizing: border-box;
  animation: fadeIn 2s ease-in-out forwards;
}


#bigData p {
  font-size: 16px;
  color: #333;
  line-height: 2rem;
}


/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #swiper {
    height: 200px;
  }

  #bigData {
    padding: 30px;
  }


  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 10px;
  }


  #whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
    font-size: 20px;
    font-weight: 700;
  }

  #whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
    font-size: 12px;
  }

  #whyChooseUs .server-block img {
    width: 48px;
    height: 48px;
  }

  #whyChooseUs .server-block > p {
    font-size: 20px;
    margin: 30px 0;
  }

  #whyChooseUs .server-block > div {
    color: #ccc;
  }
}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
  #swiper {
    height: 400px;
  }

  #bigData {
    padding: 60px;
  }

  #bigData .bigData-device {
    margin: 30px 0 15px;
  }

  #contactUs .contactUs-container h1 {
    font-size: 30px;
  }

  #contactUs .contactUs-container h3 {
    font-size: 20px;
  }

  #contactUs .contactUs-container button {
    width: 300px;
    height: 50px;
    margin-top: 30px;
  }

  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 15px;
  }

}
</style>

